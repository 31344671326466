body {
    background-color: #F0F1F1;
}

#page-content-wrapper {
    min-width: 100vw;
    padding-left: 9rem;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: 0;
}