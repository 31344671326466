.content-box-text .text.normal { color: black; }
.content-box-text .text.success { color: green; }
.content-box-text .text.warning { color: yellow; }
.content-box-text .text.danger { color: red; }

.card.content-box-text {
    height: 11rem;
}

/*
.content-box-text.card > .card-body.header {
    min-height: 3rem;
}
*/