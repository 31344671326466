#wrapper {
    overflow-x: hidden;
}

.navHeader {
    background-color: #FDBA30;
}

#sidebar-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 98;
    width: 9rem;

    /*
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    */
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .sidebar-items > .nav-item {
    width: 100%;
}

#sidebar-wrapper .sidebar-items > .nav-item > a {
    text-decoration: none;
    text-align: center;
    color: white;
    border-radius: 0;
    /*
    margin: 1rem;
    */
}

#sidebar-wrapper .sidebar-items > a:hover {
    color: #FDBA30;
}

#sidebar-wrapper .sidebar-items > .nav-item > a .icon {
    display: block;
}

#sidebar-wrapper .sidebar-items > .nav-item > a .name-text {
    display: block;
    /*font-size: 14px;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Telegraf";
}

#sidebar-wrapper .sidebar-items > .nav-item a.nav-link.active {
    background-color: #FDBA30;
}