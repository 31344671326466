.makeStylespageDropdownButton-0-2-719 {
    border: 1px solid #cdcdcd;
    margin: 0 0.5rem;
    padding: 0.4375rem 0.1875rem;
    min-width: 0.625rem;
    background: #fff;
    box-shadow: none;
    font-weight: 400;
    border-radius: 0.125rem;
}

.makeStylespageDropdownButton-0-2-719::after {
    top: 0.125rem;
    width: 0;
    border: 7px solid transparent;
    height: 0.75rem;
    margin: 0 0.625rem;
    content: "";
    display: inline-block;
    position: relative;
    border-width: 6px 4px 0 4px;
    vertical-align: middle;
    border-top-color: #262626;
}

.makeStylespageDropdownButton-0-2-719 span {
    padding: 0 0 0 0.625rem;
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;    
}

.makeStylespageSizeContainer-0-2-716 {
    display: flex;
    align-items: center;
}