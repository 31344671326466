.activity-stream > .activity-group {
    position: relative;
    padding-bottom: 1.25rem;
}

.activity-stream > .activity-group .stream-item {
    display: flex;
    padding: 1.125rem 0;
    z-index: 2;
}

.activity-stream > .activity-group .stream-item-container {
    position: relative;
}

.activity-stream .activity-group:after {
    content: "";
    position: absolute;
    background-color: #f0f0f0;
    border: 2px solid #cdcdcd;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 100%;
    top: 1rem;
    /* left: 0.75rem; */
    left: 13.3125rem;
    z-index: 2;
}

.activity-stream .activity-group .stream-item-container:after,
.activity-stream .activity-group:before {
    content: "";
    position: absolute;
    background-color: #cdcdcd;
    width: 0.125rem;
    height: 3.75rem;
    top: 1.625rem;
    left: 1.125rem;
}

.activity-stream > .activity-group .stream-item-container .stream-item-contents {
    padding-left: 12.5rem;
}

.activity-stream > .activity-group  ul{
    list-style: none;
}

.activity-stream > .activity-group .stream-item .element-card {
    display: flex;
    position: relative;
    min-height: 2.375rem;
    z-index: 1;
    flex: 1;
    max-width: 100%;
}

.activity-stream > .activity-group .stream-item .element-card > .element-image {
    display: flex;
    position: relative;
    background-color: #F0F1F1;
    height: 2.375rem;
    width: 2.375rem;
    margin: 0;
    margin-right: 0.625rem;
    flex-shrink: 0;
    text-align: center;
}

.activity-stream > .activity-group .stream-item .element-card > .element-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    /* overflow: hidden; */
    text-decoration: none;
    line-height: 1.1875rem;
    padding-right: 0;
    flex-grow: 1;

    border-left: 2px solid #2fd9fc;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    padding-left: 0.5625rem;
}

.activity-stream > .activity-group .stream-item .element-details {
    max-width: 100%;
}

.activity-stream > .activity-group .stream-item .element-details .timestamp {
    position: absolute;
    top: 0;
    left: -16.25rem;
    width: 11.25rem;
    color: #666;
    font-size: .75rem;
    line-height: 2.375rem;
    margin-right: 1.25rem;
    flex-shrink: 0;
    display: inline-block;
    padding: 0;
    text-overflow: inherit;
    white-space: normal;
    font-style: italic;
    overflow: visible;
    text-align: right;
}

.activity-stream > .activity-group .stream-item .element-details .context {
    color: #262626;
    font-size: .875rem;
    font-weight: 700;
}

.activity-stream > .activity-group .stream-item .element-details .name, .activity-stream > .activity-group .stream-item .element-details .name span {
    font-weight: 400;
    word-wrap: break-word;
    white-space: normal;
}

.activity-stream > .activity-group .stream-item .element-details .content {
    font-size: .875rem;
    color: #666;
}

.stream-image-icon {
    font-size: 2.375rem !important;
    color: #262626;
}

.activity-stream .activity-group .activity-group-title {
    margin-left: 15.9375rem;
}

.activity-stream .activity-group .stream-item-container:after {
    height: 100%;
    top: 0;
}

.activity-stream .activity-group ul.activity-feed>li:last-of-type:after {
    height: 2.5rem;
}

.activity-stream .activity-group .stream-item:before {
    content: "";
    position: absolute;
    background-color: rgba(255,255,255,0.85);
    opacity: 0;
    bottom: 0;
    top: 0;
    left: -0.75rem;
    right: -1.625rem;
}

.child-is-invokable {
    cursor: pointer;
}

@media only screen and (min-width: 40em) {
    .activity-stream .activity-group .stream-item-container:after,
    .activity-stream .activity-group:before {
        left: 13.6875rem;
    }

    .activity-stream .activity-group .stream-item .element-details {
        overflow: visible;
        width: calc(100% - 4.125rem);
    }
}