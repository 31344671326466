/*
.permission-denied h2 {
    font-family: "Telegraf-UltraBold";
}
*/

.permission-denied img {
    width: 30rem;
    /*
    margin-left: 9rem!important;
    margin-right: 9rem!important;
    */
}