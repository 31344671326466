body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

/* font-size */
.fs-7 {
  font-size: 0.9rem !important;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.h-82 {
  height: 82% !important
}

@import '../node_modules/react-beautiful-horizontal-timeline/card.css';

.upn-text-lucuma { color: #FDBA30; }

.upn-text-black { color: #000000 }

.upn-text-purple { color: #9500FF }

.upn-text-magenta { color: #FF00DA }

.upn-text-cyan { color: #00B9FF }

.upn-bg-body { background-color: #AFAFAF;}

.upn-bg-lucuma { background-color: #FDBA30 !important; }

/* nueva app */

svg.svg-icon.small-icon {
  width: 1rem;
  height: 1rem;
}

svg.svg-icon.large-icon {
  width: 2.3rem;
  height: 2.3rem;
}

svg.svg-icon.default {
  stroke: currentColor;
}

svg.svg-icon {
  display: inline-block;
  vertical-align: inherit;
  stroke-width: 1.1;
  vector-effect: non-scaling-stroke;
}

input[type="text"],input[type="password"],input[type="date"],input[type="datetime"],input[type="datetime-local"],input[type="month"],input[type="week"],input[type="email"],input[type="number"],input[type="search"],input[type="tel"],input[type="time"],input[type="url"],input[type="color"],textarea {
  border-color: #cdcdcd;
  padding: 0.5625rem 0.625rem;
  line-height: 1.125rem;
}

input[type="text"],input[type="password"],input[type="date"],input[type="datetime"],input[type="datetime-local"],input[type="month"],input[type="week"],input[type="email"],input[type="number"],input[type="search"],input[type="tel"],input[type="time"],input[type="url"],input[type="color"],textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #cdcdcd;
  box-shadow: none;
  color: #262626;
  display: block;
  font-family: inherit;
  font-size: .875rem;
  height: 2.3125rem;
  margin: 0 0 1rem 0;
  padding: .5rem;
  width: 100%;
  box-sizing: border-box;
  transition: border-color .15s linear,background .15s linear;
}

input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], form .row .row.collapse input, textarea, select {
  border-radius: 2px;
}

input[type="text"]:focus, input[type="password"]:focus, 
input[type="date"]:focus, input[type="datetime"]:focus, 
input[type="datetime-local"]:focus, input[type="month"]:focus, 
input[type="week"]:focus, input[type="email"]:focus, input[type="number"]:focus, 
input[type="search"]:focus, input[type="tel"]:focus, input[type="time"]:focus, 
input[type="url"]:focus, input[type="color"]:focus, textarea:focus, select:focus {
  background-color: #fff;
  border-color: #006dc7;
  box-shadow: inset 0 0 0 4px rgba(0,109,199,0.1);
  border-width: 1px;
  outline: 1px solid #006dc7;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  border-radius: 2px;
  line-height: 1 !important;
}

/**************/

.dt-header button.btn-search {
  background-color: #000000;
  color: #fff;
  transition: all 300ms ease;
  display: block;
}

.dt-header button.btn-search:hover {
  box-shadow: 0 0 0 4px #c56fd5;
  cursor: pointer
}

.text-success {
  color: #43a047
}

.text-danger {
  color: #FF0E0E
}