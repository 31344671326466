/*
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
*/

.base-courses .course-columns-header {
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    position: relative;
    justify-content: initial;
    margin: 1.875rem 0 0.9375rem;
    padding: 0 1.875rem;
}

.base-courses .course-columns-header bb-search-box {
    margin-right:1.25rem;
    width: initial;
}

/*
@include media-breakpoint-up(md) {
    .panel-header.simple-header {
        background-color: #fff;
        border-bottom: 1px solid #cdcdcd;
    }
}
*/