.course-roster-layer ul {
    list-style: none;
    padding-left: 0;
}

.list-header {
    border-bottom: 1px solid #cdcdcd;
}

.tabular-list-header {
    /*display: none;*/
    align-items: center;
    min-height: 2.5rem;
    border-bottom: 1px solid #cdcdcd;
    font-weight: 600;
}

.user-role {
    background-color: #262626;
    border-radius: 20px;
    padding: 0 0.625rem 0.0625rem;
    display: inline-block;
    color: #fff !important;
    text-transform: uppercase;
    font-size: .625rem;
    font-weight: 700;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    word-wrap: normal !important;
    line-height: 1.125rem;
    max-height: 1.125rem;
    max-width: 100%;
    transition: max-width 250ms cubic-bezier(0.55, 0, 0.1, 1);
    width: max-content;
}

.tabular-row:before {
    left: 4.25rem;
    width: calc(100% - 4.25rem);
    bottom: 0;
}

.tabular-row:before {
    content: '';
    border-top: 1px solid #cdcdcd;
    height: 0.0625rem;
    width: calc(100% - 3.9375rem);
    position: absolute;
    bottom: -0.0625rem;
    left: 3.9375rem;
    z-index: 1;
}

.tabular-row {
    display: flex;
    align-items: center;        
    margin-bottom: 0 !important;
    position: relative;
    /* 
    padding: 0.9375rem 0;
    flex-direction: column 
    min-height: 3.625rem;*/
}

.tabular-row:hover {
    background-color: #f0f0f0
}