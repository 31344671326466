.custom-modal>.modal-fullscreen {
    /*
    width: 95vw !important;
    margin-left: 5vw !important;
    */
    
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.custom-modal {
    right: 0% !important;
    /*display: inline-table !important;*/
}

.custom-modal .modal-header {
    border-bottom: 0.0625rem solid rgb(205, 205, 205) !important;
}

.custom-modal .modal-body {
    background-color: #fff;
}

.custom-modal .modal-header>.btn-close {
    position: absolute !important;
    background-color: #fcb830 !important;
    color: #000000 !important;
    border-radius: 0;
    -webkit-appearance: none;
    opacity: 1 !important;
    box-shadow: none!important;
    z-index: 1060 !important;
}

.custom-modal .modal-header>.btn-close::after {
    position: absolute;
    content: '';
    top: 100%;
    left: 0;
    border-style: solid;
    border-width: 0 24px 17px 0;
    border-color: transparent #bd8a24 transparent transparent;
}

/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) { ... } */

/* Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) { ... } */

/* Large devices (desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    .custom-modal .modal-header>.btn-close {
        background-color: #c56fd5;
        color: #fff !important;
        right: auto;
        left: -1.5rem;
        top: 1.875rem;
        height: 2.5rem;
        width: 2.5rem;
        font-weight: 450;
        font-size: 0.95rem;
        padding: 0.05rem !important;
    }

    .custom-modal .modal-header {
        padding-left: 1.9rem;
        padding-right: 1.9rem;
    }
}

.custom-modal .modal-body {
    padding: 1rem 4rem;
}

/* Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) { ... } */