.panel-footer .notification-container .progress-indicator {
    height: 1.5rem;
    /* width: 1.5rem; 
    margin-right: 0; */
    /* background-color: #c56fd5; */
}

.panel-footer .notification-container .progress-indicator+.progress-status {line-height: 2rem;}

.panel-footer.notification .notification-container{
    width:auto;
    box-shadow:none !important;
    padding:1.25rem 2.5rem 1.25rem 0.625rem;
    /*Agregado */
    /* justify-content: space-between; */
    align-items: center;
}

@media only screen and (max-width: 39.9375em){
    .panel-footer.notification .notification-container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content:center
    }
}

@media only screen and (min-width: 40em){
    .panel-footer.notification .notification-container{
        background-color:#f8f8f8;
        border-radius:5000px;
        box-shadow:inset 0 0 0 1px #c6c6c6 !important;
        display:inline-flex;
        height:3.125rem;
        max-width:80%;
        padding:0.3125rem;
        padding-right:1.25rem;
        /* padding-bottom:0 */
    }
}

@media only screen and (max-width: 39.9375em){
    .panel-footer.notification .notification-container .progress-indicator,
    .panel-footer.notification .notification-container .progress-status{
        color:#fff
    }
}

.panel-footer.notification .notification-container .progress-indicator{
    /* margin-right: 0.3125rem; */
    margin-right: 0.3125rem;
    /* height: 100%; */
}

.panel-footer.notification .notification-container .progress-status{width:100%}

@media only screen and (max-width: 39.9375em){
    .panel-footer.notification .notification-container .progress-status{
        width:auto;
        max-width:calc(100% - 2.625rem);
        line-height:1.3125rem;
        overflow:initial !important;
        text-overflow:initial;
        white-space:initial !important
    }
}

.panel-footer.notification .notification-container.success svg{color:#39e379}
.panel-footer.notification .notification-container{pointer-events:auto;cursor:pointer}

.panel-footer{position:absolute;left:0;right:0;width:100%}

.panel-footer{
    bottom:0;
    height:4.375rem;
    padding:0.9375rem 1.875rem;
    z-index:2;
    background-color:#f8f8f8;
    border-top:1px solid #cdcdcd
}

.panel-footer.notification .progress-indicator.error-state{margin-right:0}

.panel-footer.notification{
    transition:max-height 500ms cubic-bezier(0.55, 0, 0.1, 1);
    box-shadow:none;
    background-color:transparent;
    border:none;
    width:auto;
    height:auto;
    overflow:hidden;
    padding:0;
    z-index:2000;
    max-height:0;
    position:fixed;
    bottom:0;
    pointer-events:none
}

@media only screen and (min-width: 40em){
    .panel-footer.notification {
        height:5.625rem;
        text-align:center
    }

    .touch .panel-footer.notification.infinite-scroll{height:7.5rem}
}

.panel-footer.notification.is-active{max-height:31.25rem}

@media only screen and (min-width: 40em){
    .panel-footer.notification .close-notification{display:none}
}

/*
.panel-footer.notification .notification-container{
    width:auto;
    background-color:#262626;
    box-shadow:none !important;
    float:initial;
    padding:1.25rem 2.5rem 1.25rem 0.625rem
}
*/

.panel-footer.notification .progress-indicator, .panel-footer.notification .progress-indicator > svg {
    width: 2.625rem;
    height: 2.625rem;
}

.progress-indicator.icon{margin-right:0}
.progress-indicator.icon > i {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.375rem;
    width: 2.625rem;
    height: 2.625rem;
    padding-top: 0.5625rem;
    text-align: center
}