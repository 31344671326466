.square-toggle {
    display: flex;
    position: relative;
    height: 1.75rem;
    width: 3.5rem;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px #cdcdcd
}

.square-toggle input {
    height: 1.75rem;
    width: 1.75rem;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0
}

.square-toggle label {
    display: inline-block;
    height: 1.75rem;
    width: 1.75rem;
    margin: 0;
    padding: 0.5rem;
    background-color: transparent !important;
    box-shadow: none;
    z-index: 1;
    color: #666
}

.square-toggle label svg {
    height: 0.75rem;
    width: 0.75rem;
    align-items: center;
    vertical-align: top;
}

.square-toggle input[type="checkbox"]+label, input[type="radio"]+label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    
    margin-bottom: .375rem;
    /* padding-left: .625rem; */
    font-weight: 400;
    color: #262626;
    line-height: 1.125rem
    
}

.square-toggle a {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.75rem;
    width: 1.75rem;
    background-color: #262626;
    border-radius: 3px 0 0 3px;
    padding: 0;
    transform: translateX(0);
    transition: transform 300ms cubic-bezier(0.55, 0, 0.1, 1);
}

.square-toggle input:checked+label+a {
    border-radius: 0 3px 3px 0;
    transform: translateX(1.75rem);
}

.square-toggle input:nth-of-type(2) {
    left: 1.75rem;
}

.square-toggle input:checked+label {
    fill: #fff;
}