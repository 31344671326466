.wrapping-input-style {
    transition: all 300ms cubic-bezier(0.55, 0, 0.1, 1);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    border: 1px solid #262626;
    font-weight: 600;
    text-align: center;
    height: 1.875rem;
    min-width: 5rem;
    max-width: 10.625rem;
    cursor: text;
    overflow: hidden;
    padding: 0 0.625rem;
}

.wrapping-input-style.green {
    background-color: #39e379;
    border: 1px solid transparent;
    color: #000;
}