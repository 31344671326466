bb-search-box>div {
    position: relative;
    padding: 0;
    min-width: 15.3125rem;    
}

bb-search-box>div>input[type='text'] {
    -webkit-padding-start: 2.1875rem;
    padding-inline-start:2.1875rem;
    border-width: 1px;
    line-height: 1.125rem;
    width: 100%;
    border-width: 1px;
    margin-bottom: 0;
}

bb-search-box>div>bb-svg-icon[icon='search'] {
    position: absolute;
    top: 0.6875rem;
    color: #666;
    left: 0.625rem
}

bb-search-box>div>button {
    position: absolute;
    top: 0.6875rem;
    color: #666;
    right: 0.5625rem;
}

bb-search-box>div>button:hover {
    color: #262626
}