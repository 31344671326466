#wrapper {
    overflow-x: hidden;
}

.progress {
    height: 2rem !important;
}

.progress>.progress-bar.bg-color-success {
    /*background-color: #5EA654;*/
    background-color: #F3B02F;
}

.progress>.progress-bar.bg-color-danger {    
    /*background-color: #E15759*/
    background-color: #AAAAAA
}
