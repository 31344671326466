@font-face {
  font-family: "Telegraf";
  src: local("Telegraf"), url("./fonts/Telegraf/Telegraf-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;  
}

@font-face {
  font-family: "Telegraf";
  src: local("Telegraf"), url("./fonts/Telegraf/Telegraf-UltraBold-800.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Telegraf" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-modal>.modal-content>.modal-body>.sl-spinner3 {
  height: 100%;
}

.progress-indicator.loading {
  --b: 0.35rem;  /* border thickness */
  --n: 8;    /* number of dashes*/
  --g: 10deg; /* gap  between dashes*/
  --c: #C56FD5;   /* the color */
  
  /* width:100px; */ /* size */    
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px; /* get rid of bad outlines */
  background: conic-gradient(#0000,var(--c)) content-box;
  --_m: /* we use +/-1deg between colors to avoid jagged edges */
    repeating-conic-gradient(#0000 0deg,
       #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
       #0000     calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
    radial-gradient(farthest-side,#0000 calc(98% - var(--b)),#000 calc(100% - var(--b)));
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}

@keyframes load {to{transform: rotate(1turn)}}

.rdt_Table > .rdt_TableBody > .rdt_TableRow > .rdt_TableCell span.progress-indicator.loading {
  display: flex;
  width: 35px;
  height: 35px;
}

[data-column-id="feedback"].rdt_TableCell p {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.is-empty-wrapper > svg {
  max-width: 14.5rem;
  max-height: 14.5rem;
  margin-bottom: 1.24rem;
}

.is-empty-wrapper > h1.is-empty-heading {  
  font-size: 1.125rem;
  margin-top: 0.75rem;
  /* margin-bottom: 1.25rem; */
}

.is-empty-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  
}